import { MenuItem } from "components/MenuItem/MenuItem";
import {useMsal} from "@azure/msal-react";
import {MenuButton} from "components/MenuItem/MenuButton";
import {useTranslate} from "hooks/useTranslate";
import {LinkButton} from "components/LinkButton/LinkButton";
import preval from "preval.macro";
import {Updater} from "components/Updater/Updater";
import {useQuery} from "@apollo/client";
import {GetSitesDocument} from "generated/graphql";
import {setSite, useSite} from "hooks/useSite";

const dt: string = preval`module.exports = new Date().toISOString().substr(0, 16)`;

const lngs = {
  en: { nativeName: 'English' },
  nl: { nativeName: 'Nederlands' },
  es: { nativeName: 'español' }
};

export const Main = () => {
  const { instance, accounts } = useMsal();
  const { t, i18n } = useTranslate("main");
  const currentLanguage = i18n.resolvedLanguage.split('-')[0];

  const site = useSite();
  const { data: sites } = useQuery(GetSitesDocument);

  return (
    <div className="menu">
      <Updater />

      <MenuItem 
        name={t("pollen")}
        link="pollen" 
        img="/strawberry2.png" 
        className="col-pollen"
      />
      <MenuItem
        name={t("emasculate")}
        link="emasculate"
        img="/emasculatie.png"
      />
      <MenuItem
        name={t("crossing")}
        link="crossing"
        img="/bee.png"
      />
      <MenuItem
        name={t("fruits")}
        link="fruits"
        img="/strawberry.png"
      />
      <MenuButton onClick={() => instance.logout()}>
        {t("logout")}
        <div>{ accounts[0].name }</div>
      </MenuButton>

      <div className="footer">
        <div style={{marginBottom: 5}}>{t('lang')}</div>
        {Object.keys(lngs).filter(l => l !== currentLanguage).map((lng) => (
          <div key={lng}><LinkButton key={lng} type="submit" onClick={() => i18n.changeLanguage(lng)}>
            {t(`switch-${lng}`)}
          </LinkButton></div>
        ))}
      </div>

      <div className="footer">
        {t('country')}:&nbsp;
        <select value={site} onChange={e => setSite(+e.currentTarget.value)}>
          <option value="">{t('all')}</option>
          { sites && sites.sites.map(s => <option value={s.id} key={s.id}>{s.name}</option>)}
        </select>
      </div>

      <div className="footer" style={{fontStyle: "italic"}}>
        Build: {dt}
      </div>
    </div>
  );
}
