import {EventType} from "generated/graphql";
import {AddOrUpdateEvent, useAddOrUpdateEvent} from "hooks/useAddOrUpdateEvent";
import {CountCard} from "components/CountCard/CountCard";
import {useTranslate} from "hooks/useTranslate";
import {CrossingProps} from "routes/Harvest/Harvest";

export const HarvestPlot = ({ crossing }: CrossingProps) => {
  const { t } = useTranslate("fruits");

  const types = [EventType.Collect25, EventType.Collect50, EventType.Collect75, EventType.Collect100, EventType.Fail];

  const events = new Map<EventType, AddOrUpdateEvent>([
    [EventType.Collect25, useAddOrUpdateEvent(EventType.Collect25)],
    [EventType.Collect50, useAddOrUpdateEvent(EventType.Collect50)],
    [EventType.Collect75, useAddOrUpdateEvent(EventType.Collect75)],
    [EventType.Collect100, useAddOrUpdateEvent(EventType.Collect100)],
    [EventType.Fail, useAddOrUpdateEvent(EventType.Fail)]
  ]);

  const getSymbol = (type: EventType) => {
    switch (type) {
      case EventType.Collect25: return '¼';
      case EventType.Collect50: return '½';
      case EventType.Collect75: return '¾';
    }
    return undefined;
  }

  const getWeight = (type: EventType) => {
    console.log(type);
    switch (type) {
      case EventType.Collect25: return 0.25;
      case EventType.Collect50: return 0.5;
      case EventType.Collect75: return 0.75;
      case EventType.Collect100: return 1;
    }
    return 0;
  }

  const total = types.reduce((o, e) =>
      o + getWeight(e) * (events.get(e)?.getEvent({ crossingId: crossing.id })?.count ?? 0), 0);

  return <>
    <h2>{crossing.code} ({total} {t('harvested')})</h2>

    { types.map(c =>
      <CountCard key={c}
                 symbol={getSymbol(c)}
                 title={t(c.toString())}
                 input={{ crossingId: crossing.id }}
                 addOrUpdate={events.get(c)}
      />) }
  </>
}