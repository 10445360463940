/* eslint-disable react-hooks/rules-of-hooks */
import styles from "./WithPlotSelector.module.css";
import {MenuItem} from "../MenuItem/MenuItem";
import {useTranslate} from "hooks/useTranslate";
import {PlotInfoFragment} from "generated/graphql";
import {ComponentType, useState} from "react";
import {ScanBox} from "components/ScanBox/ScanBox";
import {MenuButton} from "components/MenuItem/MenuButton";

interface Props {
  position: { locationNumber: number, table: number };
  selector: (plot: PlotInfoFragment) => number;
  plots: PlotInfoFragment[];
  isCrossing?: boolean;
}

export interface PlotProps {
  plot: PlotInfoFragment;
}

export const WithPlotSelector = (WrappedComponent: ComponentType<PlotProps>) => {
  return ({position, selector, plots, isCrossing = false}: Props) => {
    const [index, setIndex] = useState(-1);
    const {t} = useTranslate("plot-selector");

    const showPlot = (p: PlotInfoFragment) =>
      (selector(p) > 0 && (!isCrossing || p.crossingsMother.filter(c => c.hasActiveReservation).length > 0))
      || (!isCrossing && (p.counts.toSelf ?? 0) > 0);

    const hasNext = index !== -1 && plots.slice(index + 1).filter(showPlot).length > 0;

    function toNext() {
      if (!hasNext) {
        return;
      }
      const next = plots.slice(index + 1).filter(showPlot)[0];
      setIndex(plots.indexOf(next));
    }

    function handleScan(input: string) {
      const target = plots.filter(p => p.code === input)[0];
      if (target) {
        setIndex(plots.indexOf(target));
      }
    }

    return <div>
      {index === -1 && <>
          <ScanBox onInput={handleScan}/>
          <h1>{t('table-header', position)}</h1>
          <div className={styles['subtitle']}>
            {t('choose-plot')}
          </div>
          <div>
            {plots.map((p, i) =>
              <div key={p.id}> { showPlot(p) &&
                <div onClick={() => setIndex(i)} className={styles['plot']}>
                  <span className={styles['code']}>{p.code}</span>
                  <span><div>{t('needed')}</div>{selector(p) < 0 ? 0 : selector(p)}</span>
                  { !isCrossing && <span><div style={{ visibility: p.counts.toSelf === null ? "hidden" : "inherit" }}>Selfing</div>{(p.counts.toSelf ?? 1) < 0 ? 0 : p.counts.toSelf}</span> }
                </div> }
              </div>
            )}
          </div>
          <MenuItem
              name={t('back')}
              img="/bee.png"
              link=".."/>
      </>}
      {index !== -1 && <>
          <div className={styles.location}>{t('table-header', position)}</div>
          <h1>Plot {plots[index].code}</h1>
          <WrappedComponent plot={plots[index]}/>

        { hasNext && <MenuButton img="/bee.png" onClick={toNext}>
            {t('next')}
          </MenuButton>}
          <MenuButton img="/bee.png" onClick={() => setIndex(-1)}>
            {t('to-overview')}
          </MenuButton>
      </>
      }
    </div>
  }
}