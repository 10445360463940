import {PlotProps} from "components/PlotSelector/WithPlotSelector";
import {EventType, GetPlotDocument} from "generated/graphql";
import {useAddOrUpdateEvent} from "hooks/useAddOrUpdateEvent";
import {useQuery} from "@apollo/client";
import {CountCard} from "components/CountCard/CountCard";
import {useTranslate} from "hooks/useTranslate";

export const CrossingPlot = ({ plot }: PlotProps) => {
  const crossing = useAddOrUpdateEvent(EventType.Crossing);
  const { t } = useTranslate("crossing");

  const { data, loading } = useQuery(GetPlotDocument, {
    variables: { plotId: plot.id }
  });

  return <div>
    <div>
      { loading && <i>Loading...</i> }
      { data && data.plot.crossingsMother.filter(c => c.hasActiveReservation).map(c =>
        <CountCard title={c.code}
                   subTitle={`${t('cross-with')} ${c.father?.code}`}
                   key={c.id}
                   required={c.activeReservationCount}
                   input={{ crossingId: c.id }}
                   addOrUpdate={crossing}
                   subTitleStyle={{ color: (c.father?.year ?? 0) % 2 === 0 ? "#b2ff66" : "#66b2ff" }}
        />)}
    </div>
  </div>
}