import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {register} from 'serviceWorkerRegistration';
import {AuthProvider} from "providers/AuthProvider";
import './i18n/i18n';
import {updateStatus} from "components/Updater/Updater";
import {init} from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {environment, sentryDsn} from "env";

init({
  dsn: sentryDsn,
  environment: environment,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const root = ReactDOM.createRoot(
document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

register({
  onUpdate: () => {
    updateStatus(true);
  }
});