import { MenuItem } from "components/MenuItem/MenuItem";
import {useTranslate} from "hooks/useTranslate";

export const Pollen = () => {
    const { t } = useTranslate('pollen');

    return (
      <div>
        <h1>{t('title')}</h1>
        <div className="menu">
        <MenuItem 
          name={t('flowers.title')}
          link="/flowers"
          img="/emasculatie.png"
          className="col-pollen"
        />
        <MenuItem name={t('epjes.EP_IN')} link="/ep-in" img="/epjesin.png"/>
        <MenuItem name={t('epjes.EP_RETURN')} link="/ep-return" img="/epjesterug.png" />
        <MenuItem name={t('epjes.EP_OUT')} link="/ep-out" img="/epjesuit.png"/>
        <MenuItem name={t('back')} link="/" img="/bee.png" />
      </div>
    </div>
    );
}