import {Link, useNavigate} from "react-router-dom";
import { useQuery } from "@apollo/client";
import {GetPositionsDocument, GetPositionsQuery, PlotType, RoomInfoFragment} from "generated/graphql";
import { useState } from "react";
import { MenuButton } from "components/MenuItem/MenuButton";
import styles from './PositionSelector.module.css';
import {MenuItem} from "../MenuItem/MenuItem";
import {useTranslate} from "hooks/useTranslate";
import {ScanBox} from "components/ScanBox/ScanBox";
import {useSite} from "hooks/useSite";

interface Props {
  type: PlotType;
}

export const PositionSelector = ({ type }: Props) => {
  const siteId = useSite();
  const { loading, data } = useQuery<GetPositionsQuery>(GetPositionsDocument, {
    variables: { type, siteId }
  });
  const [location, setLocation] = useState<RoomInfoFragment | null>(null);

  const { t } = useTranslate("position-selector");
  const navigate = useNavigate()

  function handleScan(input: string) {
    const parts = input.split('-');
    if (parts.length !== 2 || !data) return;

    const loc = data.positions.filter(t => t.locationNumber === +parts[0])[0];
    if (!loc) return;
    const tab = loc.positions.filter(t => t.table === +parts[1])[0];
    if (tab) {
      navigate(`./${tab.id}`);
    }
  }

  return (
    <div>
      {loading && <div><i>{ t("loading") }</i></div>}

      { data && <ScanBox onInput={handleScan} />}

      {data && !location && <div>
          <div>{ t("introduction") }</div>{
        data.positions.map(p =>
          <MenuButton
            key={p.locationNumber}
            img="/strawberry2.png"
            onClick={() => setLocation(p)}
          >
            {t('greenhouse')} {p.locationNumber}
          </MenuButton>
        )
      }
          <MenuItem img="bee.png" link="/" name={t('back')} />
      </div>
      }

      {location && <div>
          <h2>{t('greenhouse')} {location.locationNumber}</h2>
          <div className={styles['subtitle']}>
            {t('choose-table')}
          </div>
          <div>
            {location.positions.map(p =>
              <Link key={p.id} to={`${p.id}`}><div className={styles['semi-button']}>
                {p.table}
              </div></Link>)}
          </div>

          <MenuButton
              img="bee.png"
              onClick={() => setLocation(null)}
          >
            {t('back')}
          </MenuButton>
      </div>}
    </div>
  );
}