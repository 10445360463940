import css from './InputBox.module.css';
import {ChangeEvent, useState} from "react";

interface Props {
  onInput: (input: string) => void;
}

export const InputBox = ({ onInput }: Props) => {
  const [current, setCurrent] = useState('');

  function process(el : HTMLInputElement) {
    if (el.value === '') return;
    onInput(el.value.trim());
    setCurrent('');
  }

  function handleChange(ev: ChangeEvent<HTMLInputElement>) {
    const val = ev.currentTarget.value;
    if (val.length > current.length + 1) {
      process(ev.currentTarget);
    } else {
      setCurrent(val);
    }
  }

  return <input
    className={css.box}
    type="text"
    autoFocus
    value={current}
    onKeyDown={e => (e.code === "Enter" || e.key === "Enter") && process(e.currentTarget)}
    onChange={e => handleChange(e)}
  />
}