import {CrossingProps} from "routes/Harvest/Harvest";
import {useTranslate} from "hooks/useTranslate";
import {EventType} from "generated/graphql";
import {useState} from "react";
import {useAddEvent} from "hooks/useAddEvent";
import styles from "./LostPlot.module.css";

export const LostPlot = ({ crossing }: CrossingProps) => {
  const { t } = useTranslate("fruits");

  const [number, setNumber] = useState<number | null>(null);

  const [addEvent] = useAddEvent();

  const process = async () => {
    console.log(number);
    if (!number) { return }
    let count = Math.round(number * 4);
    if (count !== number * 4) {
      alert(t('multiple'));
      return;
    }
    if (count > crossing.result * 4) {
      count = crossing.result * 4;
    }
    if (count < 0) { return }
    await addEvent({
      variables: {
        type: EventType.Lost25,
        count,
        crossingId: crossing.id
      }
    })
  }

  return <>
    <h2>{crossing.code} ({crossing.result} {t('harvested')})</h2>

    <div>
      <label>{t('number')}</label>
    </div>
    <div>
      <input type="number" min={0} step={0.25}
             className={styles['input-box']}
             value={number ?? undefined}
             onChange={e => setNumber(e.currentTarget.valueAsNumber)} />
      <button onClick={process} className={styles.button}>{t('remove')}</button>
    </div>
  </>;
}