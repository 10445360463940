import {useState} from "react";
import {makeVar, useReactiveVar} from "@apollo/client";
import {useTranslate} from "hooks/useTranslate";

export const updateStatus = makeVar(false);

export const Updater = () => {
  const status = useReactiveVar(updateStatus);
  const [working, setWorking] = useState(false);

  const { t } = useTranslate("main");

  function update() {
    setWorking(true);
    window.location.reload();
  }

  return <>
    { status && <div style={{textAlign: "center", paddingTop: 20, paddingBottom: 10}}>
        <button disabled={working} onClick={update}>{t('update')}</button>
    </div>}
  </>
}