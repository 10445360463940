import {InputBox} from "components/InputBox/InputBox";
import {EventType} from "generated/graphql";
import {useTranslate} from "hooks/useTranslate";
import {useAddOrUpdateEvent} from "hooks/useAddOrUpdateEvent";
import {MenuItem} from "components/MenuItem/MenuItem";
import {CountCard} from "components/CountCard/CountCard";
import {useRef} from "react";
import {ScanBox, ScanBoxRef} from "components/ScanBox/ScanBox";

interface Props {
  type: EventType;
}

export const EpjesIn = ({type}: Props) => {
  const { t } = useTranslate('pollen.epjes');

  const scanBoxRef = useRef<ScanBoxRef>(null);

  const addOrUpdate = useAddOrUpdateEvent(type, () => {
    scanBoxRef.current?.focus();
  });

  return (
    <div>
      <h1 className="header">{t(type.toString())}</h1>
      <p>{t('introduction')}</p>
      <div>
        <ScanBox onInput={p => addOrUpdate.process({ plotCode: p })} autoFocus={false} ref={scanBoxRef} />
        <InputBox onInput={p => addOrUpdate.process({ plotCode: p })} />

        <div style={{ color: 'red', marginTop: "5px", marginBottom: "25px" }}>
          {addOrUpdate.error}
        </div>

        <div>{t('scanned')}: {addOrUpdate.events.reduce((c, e) => c + e.count, 0)}</div>

        <div>
          {addOrUpdate.events.map(e => <CountCard
            key={e.eventId}
            title={e.plot.plotCode ?? ""}
            addOrUpdate={addOrUpdate}
            input={e.plot}
          />)}
        </div>

        <MenuItem img="bee.png" link="/" name={t('back')} />
      </div>
    </div>
  );
}