import {PlotProps} from "components/PlotSelector/WithPlotSelector";
import {EventType, GetSitesDocument} from "generated/graphql";
import {useAddOrUpdateEvent} from "hooks/useAddOrUpdateEvent";
import {CountCard} from "components/CountCard/CountCard";
import {useTranslate} from "hooks/useTranslate";
import {useQuery} from "@apollo/client";
import {getAvailableEmasculations} from "helpers/countHelpers";

export const EmasculatePlot = ({ plot }: PlotProps) => {
  const emasculate = useAddOrUpdateEvent(EventType.Emasculate);
  const selfing = useAddOrUpdateEvent(EventType.Selfing);
  const { t } = useTranslate("emasculate");
  const sites = useQuery(GetSitesDocument).data?.sites;
  const site = sites?.filter(f => f.id === plot.siteId)[0];

  return <div>
    <CountCard title={t('emasculate')}
               required={getAvailableEmasculations(plot)}
               input={{ plotId: plot.id }}
               addOrUpdate={emasculate}
    />

    { plot.counts.toSelf !== null && <CountCard title={t('selfing')}
               required={plot.counts.toSelf}
               input={{ plotId: plot.id }}
               addOrUpdate={selfing}
               allowMore={true}
    /> }

    { site && <div style={{marginBottom: 20}}>{t('total-today')}: {site.emasculatedToday}</div> }
  </div>
}