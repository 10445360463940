import css from "./StatusBar.module.css";
import {makeVar, useReactiveVar} from "@apollo/client";
import {useTranslate} from "hooks/useTranslate";
import {environment} from "env";

export enum Status {
  Connecting= "connecting",
  Connected = "connected",
  Disconnected = "disconnected"
}

export const connectionStatus = makeVar(Status.Connecting);

export const StatusBar = () => {
  const {t} = useTranslate('status');
  const status = useReactiveVar(connectionStatus);

  if (environment !== "production" && status === Status.Connected) {
    return <div className={`${css.bar} test`}>{t('test')}</div>
  }

  return <div className={`${css.bar} ${status}`}>{t(status.toString())}</div>
}