import {ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef} from "react";

interface Props {
  onInput: (input: string) => void;
  autoFocus?: boolean;
}

export interface ScanBoxRef {
  focus: () => void;
}

export const ScanBox = forwardRef( ({ onInput, autoFocus = true }: Props, fref: ForwardedRef<ScanBoxRef>) => {
  const ref = useRef<HTMLInputElement>(null);

  useImperativeHandle(fref, () => ({
    focus
  }));

  function focus() {
    if (ref.current) {
      ref.current.readOnly = true;
      ref.current.focus();
      setTimeout(unsetReadOnly, 100);
    }
  }
  useEffect(focus);

  function unsetReadOnly() {
    if (ref.current) {
      ref.current.readOnly = false;
    }
  }

  return <input
    ref={ref}
    type="text"
    onBlur={() => autoFocus && setTimeout(focus, 500)}
    onInput={e => { onInput(e.currentTarget.value.trim()); e.currentTarget.value = ''; }}
    style={{ pointerEvents: "none", position: "absolute", left: -200 }}
  />
});