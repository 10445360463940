import styles from './MenuItem.module.css';
import {PropsWithChildren} from "react";


interface Props {
  img?: string;
  onClick: () => void
}


export function MenuButton(props: PropsWithChildren<Props>) {
  return (
    <div className={styles['menu-item']} onClick={props.onClick}>
      <img className="icon" alt=""
        src={props.img}
      />
      <span className={styles['title']}>{props.children}</span>
    </div>
  );
}