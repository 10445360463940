import {useParams} from "react-router-dom";
import {useApolloClient, useQuery} from "@apollo/client";
import {
  GetPlotsDocument,
  PlotFilter, PlotInfoFragment,
  PlotType,
  PositionFragmentDoc
} from "generated/graphql";

import {useTranslate} from "hooks/useTranslate";
import {PlotProps, WithPlotSelector} from "components/PlotSelector/WithPlotSelector";
import {ComponentType, useEffect, useMemo, useState} from "react";

interface Props {
  contentElement: ComponentType<PlotProps>;
  selector: (plot: PlotInfoFragment) => number;
  type: PlotType;
  useFilter?: boolean;
  isCrossing?: boolean;
}

export const PerTable = ({ contentElement, selector, type, isCrossing = false, useFilter = false }: Props) => {
  const MainContent = useMemo(() => WithPlotSelector(contentElement), [contentElement]);

  const { positionId } = useParams<{ positionId: string }>();
  if (!positionId) throw new Error("Missing position id");

  const posId = +positionId;

  const [, forceUpdate] = useState<{ }>();

  const client = useApolloClient();
  const position = client.readFragment({
    id: `Position:${posId}`,
    fragment: PositionFragmentDoc
  });
  useEffect(() => {
    if (!position) {
      setTimeout(() => forceUpdate({}), 500);
    }
  })
  const plotsQuery = useQuery(GetPlotsDocument, {
      variables: { positionId: posId, type: type, filter: useFilter ? PlotFilter.Priority : PlotFilter.None },
      fetchPolicy: "cache-and-network"
    });

  const { t } = useTranslate("pollen.flowers.per-table");

  return <div>
    { plotsQuery.loading && <div><i>{t('loading')}</i></div>}
    { plotsQuery.data && position && <>
        <MainContent position={position}
                     plots={plotsQuery.data.plots}
                     selector={selector}
                     isCrossing={isCrossing}
        />
    </>}
  </div>
}