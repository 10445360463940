import { Link } from "react-router-dom";
import styles from './MenuItem.module.css';

export function MenuItem(props: any) {
    return (
      <Link to={props.link} className={styles['menu-item']} relative="path">
        <img className="icon" alt=""
          src={props.img}
          />
        <span className={styles['title']}>{props.name}</span>
    </Link>
    );
  }