import {PropsWithChildren, useState} from "react";
import {Loader} from "components/LoadingButton/Loader";

interface Props {
  onClick: () => Promise<void> | undefined
}

export const LoadingButton = ({ onClick, children }: PropsWithChildren<Props>) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    await onClick();
    setLoading(false);
  }

  return <button onClick={handleClick} disabled={loading} style={{ position: "relative" }}>
    {children}
    { loading && <div style={{ position: 'absolute', left: -9, top: 15 }}>
        <Loader />
    </div> }
  </button>
}