import {useTranslate} from "hooks/useTranslate";
import {PositionSelector} from "components/PositionSelector/PositionSelector";
import {PlotType} from "generated/graphql";

export const Crossing = () => {
  const { t } = useTranslate("crossing");

  return <div>
    <h1>{t('header')}</h1>
    <PositionSelector type={PlotType.Mother} />
  </div>
}