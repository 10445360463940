import {useTranslate} from "hooks/useTranslate";
import {PositionSelector} from "components/PositionSelector/PositionSelector";
import {PlotType} from "generated/graphql";

export const Emasculate = () => {
  const { t } = useTranslate("emasculate");
  
  return <div>
    <h1>{t('header')}</h1>
    <PositionSelector type={PlotType.Mother} />
  </div>
}