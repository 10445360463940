import {useTranslate} from "hooks/useTranslate";
import {ComponentType, useState} from "react";
import {useQuery} from "@apollo/client";
import {GetCrossingDocument} from "generated/graphql";
import {InputBox} from "components/InputBox/InputBox";
import {MenuButton} from "components/MenuItem/MenuButton";
import {MenuItem} from "components/MenuItem/MenuItem";
import {ScanBox} from "components/ScanBox/ScanBox";

export interface CrossingProps {
  crossing: { id: number, code: string, result: number };
}

interface Props {
  label: string
  child: ComponentType<CrossingProps>
}

export const Harvest = ({ label, child }: Props) => {
  const { t } = useTranslate("fruits");
  const [crossingCode, setCrossingCode] = useState('');

  const { data, loading, error } = useQuery(GetCrossingDocument, {
    variables: { crossingCode: crossingCode },
    skip: !crossingCode
  });

  const ChildComponent = child;

  return <div>
    <h1>{t(label)}</h1>
    { !crossingCode && <>
        <div>{t('intro')}</div>
        <InputBox onInput={setCrossingCode} />
        <MenuItem img="bee.png" link="/" name={t('back')} />
    </>}
    { loading && <div><i>{t('loading')}</i></div> }
    { data && <div>
        <ScanBox onInput={setCrossingCode} autoFocus={label !== 'lost'} />
        <ChildComponent crossing={data.crossing} />

        <MenuButton
            img="bee.png"
            onClick={() => setCrossingCode('')}
        >
          {t('next')}
        </MenuButton>
    </div> }
    { error && <div>
      {t('not-found', { crossingCode })}
        <MenuButton
            img="bee.png"
            onClick={() => setCrossingCode('')}
        >
          {t('back')}
        </MenuButton>
    </div> }
  </div>
}