import {EventType, MutationStatus} from "generated/graphql";
import {useState} from "react";
import {useAddEvent} from "hooks/useAddEvent";
import {useUpdateEvent} from "hooks/useUpdateEvent";
import {useTranslate} from "hooks/useTranslate";

export interface Event {
  count: number;
  plot: Input;
  eventId?: number;
}

export interface Input {
  plotCode?: string,
  plotId?: number,
  crossingId?: number
}

export interface AddOrUpdateEvent {
  process: (input: Input) => Promise<void>
  events: Event[]
  error: string
  undo: (ev?: Event) => Promise<void>
  getEvent: (input: Input) => Event | undefined
}

export const useAddOrUpdateEvent = (type: EventType, onChange?: () => void) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [error, setError] = useState('');
  const [addEvent] = useAddEvent();
  const [updateEvent] = useUpdateEvent();
  const { t } = useTranslate("card");

  async function addOrUpdateEvent(ev: Event) {
    if (ev.eventId) {
      const res = await updateEvent({variables: {eventId: ev.eventId, count: ev.count}});
      if (res.data?.updateEvent.status !== MutationStatus.Locked) {
        return res.data?.updateEvent;
      }
      ev.count = 1; // TODO: handle locked case correctly
    }
    const res = await addEvent({ variables: {
        plotId: ev.plot.plotId,
        plotCode: ev.plot.plotCode,
        crossingId: ev.plot.crossingId,
        count: ev.count,
        type: type
      } });
    return res.data?.addEvent;
  }

  function getEvent(input: Input): Event | undefined {
    return events.filter(e => (e.plot.plotCode && e.plot.plotCode === input.plotCode)
      || (e.plot.plotId && e.plot.plotId === input.plotId)
      || (e.plot.crossingId && e.plot.crossingId === input.crossingId))[0];
  }

  async function process(input: Input) {
    setError('');
    const current = getEvent(input);
    const newEvent: Event = {
      plot: input,
      count: (current?.count ?? 0) + 1,
      eventId: current?.eventId
    };
    const res = await addOrUpdateEvent(newEvent);
    if (res?.event?.id) {
      newEvent.eventId = res.event.id;
      if (current) {
        current.count = newEvent.count;
        setEvents(events);
      } else {
        setEvents([newEvent, ...events.filter(e => e !== current)]);
      }
      onChange && onChange();
    } else if (res?.status === MutationStatus.NotFound) {
      setError( t('not-found', { plotCode: input.plotCode }) ?? "Not found");
    }
  }

  async function undo(ev?: Event) {
    if (!ev) ev = events[0];
    if (ev.count === 0 || !ev.eventId) return;
    const newEvent: Event = {
      plot: ev.plot,
      count: ev.count - 1,
      eventId: ev.eventId
    };
    await updateEvent({
      variables: { eventId: ev.eventId, count: newEvent.count }
    });
    ev.count = newEvent.count;
    setEvents(events);
    onChange && onChange();
  }

  return { process, events, error, undo, getEvent };
}