import {useTranslate} from "hooks/useTranslate";
import {MenuItem} from "components/MenuItem/MenuItem";

export const Fruits = () => {
  const { t } = useTranslate('fruits');

  return (
    <div>
      <h1>{t('title')}</h1>
      <div className="menu">
        <MenuItem name={t('harvest')} link="/harvest" img="/strawberry.png"/>
        <MenuItem name={t('lost')} link="/lost" img="/aardbeiweg.png"/>
        <MenuItem name={t('back')} link="/" img="/bee.png" />
      </div>
    </div>
  );
}