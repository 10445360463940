import {ApolloClient, NormalizedCacheObject} from "@apollo/client";
import {GetPlotsDocument, GetPositionsDocument, PlotFilter, PlotType} from "generated/graphql";

export const preload = async (client: ApolloClient<NormalizedCacheObject>, siteId: number | undefined) => {
  for (const type of [PlotType.Father, PlotType.Mother]) {
    const res = await client.query({
      query: GetPositionsDocument,
      variables: {type, siteId}
    });
    for (const pos of res.data.positions.flatMap(p => p.positions)) {
      const filters = type === PlotType.Mother ? [PlotFilter.Priority, PlotFilter.None] : [PlotFilter.None];
      for (const filter of filters) {
        await client.query({
          query: GetPlotsDocument,
          variables: {positionId: pos.id, type: type, filter}
        });
      }
    }
  }
}