import {useTranslate} from "hooks/useTranslate";
import {PositionSelector} from "components/PositionSelector/PositionSelector";
import {PlotType} from "generated/graphql";


export const Bloemen = () => {
  const { t } = useTranslate('pollen.flowers')

  return (
    <div>
      <h1>{ t("title") }</h1>
      <PositionSelector type={PlotType.Father} />
    </div>
  );
}