import {makeVar, useReactiveVar} from "@apollo/client";

const val = +(window.localStorage.getItem("activeSite") ?? "");

const activeSite = makeVar<number | undefined>(val > 0 ? val : undefined);

export const useSite = () => {
  return useReactiveVar(activeSite);
}

export const setSite = (val: number | undefined) => {
  const target = val && val > 0 ? val : undefined;
  activeSite(target);
  window.localStorage.setItem("activeSite", target?.toString() ?? "");
}