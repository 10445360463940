import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Main} from "routes/Main";
import {Pollen} from 'routes/Collect/Pollen';
import {Bloemen} from 'routes/Collect/Bloemen';
import {EpjesIn} from 'routes/Collect/EpjesIn';
import {EventType, PlotType} from "generated/graphql";
import {Emasculate} from "routes/Emasculate/Emasculate";
import {PerTable} from "routes/PerTable";
import {EmasculatePlot} from "routes/Emasculate/EmasculatePlot";
import {CollectPlot} from "routes/Collect/CollectPlot";
import {Crossing} from "routes/Crossing/Crossing";
import {CrossingPlot} from "routes/Crossing/CrossingPlot";
import {Harvest} from "routes/Harvest/Harvest";
import {StatusBar} from "components/StatusBar/StatusBar";
import {Fruits} from "routes/Harvest/Fruits";
import {HarvestPlot} from "routes/Harvest/HarvestPlot";
import {LostPlot} from "routes/Harvest/LostPlot";
import {getAvailableEmasculations} from "helpers/countHelpers";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />
  },
  {
    path: "pollen",
    element: <Pollen/>
  },
  {
    path: "flowers",
    element: <Bloemen/>
  },
  {
    path: "flowers/:positionId",
    element: <PerTable contentElement={CollectPlot} type={PlotType.Father} selector={c => c.counts.toCollect} />
  },
  {
    path: "ep-in",
    element: <EpjesIn type={EventType.EpIn}/>
  },
  {
    path: "ep-out",
    element: <EpjesIn type={EventType.EpOut} />
  },
  {
    path: "ep-return",
    element: <EpjesIn type={EventType.EpReturn}/>
  },
  {
    path: "emasculate",
    element: <Emasculate />
  },
  {
    path: "emasculate/:positionId",
    element: <PerTable contentElement={EmasculatePlot} type={PlotType.Mother}
                       selector={c => getAvailableEmasculations(c)} useFilter={true} />
  },
  {
    path: "crossing",
    element: <Crossing />
  },
  {
    path: "crossing/:positionId",
    element: <PerTable contentElement={CrossingPlot} type={PlotType.Mother}
                       selector={c => c.crossingsMother.reduce((z,a) => z + a.activeReservationCount,0)}
                       isCrossing={true} />
  },
  {
    path: "harvest",
    element: <Harvest label="harvest" child={HarvestPlot} />
  },
  {
    path: "fruits",
    element: <Fruits />
  },
  {
    path: "lost",
    element: <Harvest label="lost" child={LostPlot} />
  }
]);

function App() {
  return (
    <div className="App">
      <StatusBar />
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
